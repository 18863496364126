import { useState, useCallback, useEffect } from 'react'

const useGetDataByAddress = (contractMethod: any, methodName: string, account: string): [any, () => Promise<void>] => {
  const [data, setData] = useState<any>(undefined)

  const getData = useCallback(async () => {
    if (contractMethod && account && methodName) {
      try {
        const result = await contractMethod?.[methodName](account)
        setData(result)
      } catch (error) {
        console.error('useGetDataByAddress', error)
      }
    }
  }, [account, contractMethod, methodName])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useGetDataByAddress
