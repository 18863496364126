import {
  MenuItemsType,
  // DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  // TrophyIcon,
  // TrophyFillIcon,
  // NftIcon,
  // NftFillIcon,
  // MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { perpLangMap } from 'utils/getPerpetualLanguageCode'
// import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
// import { ChainId } from '@pancakeswap/sdk'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => [
  {
    label: t('Homepage'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/',
    showItemsOnMobile: false,
    items: [],
    showOnMobile: false,
    // hideSubNav: true,
  },
  {
    label: t('Swap'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Liquidity'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/liquidity',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Launchpad'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/launchpad',
    showItemsOnMobile: false,
    items: [],
  },
  // {
  //   label: t('Lockups'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/lockups',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('All Lockups'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/all-lockups',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('Claim Tokens'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/claim',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('Earn'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/earn',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  // {
  //   label: t('Win'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/win',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
] // .filter((item) => filterItemBySupportChainId(item, chainId))

export default config
