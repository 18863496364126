import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n\n  ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n\n  color: ",
        ";\n  font-size: 13px;\n  font-weight: 500;\n  text-align: center;\n\n  ",
        " {\n    font-size: 16px;\n  }\n\n  ",
        "\n\n  ",
        "\n\n  // background: ",
        ";\n  // box-shadow: ",
        ";\n  border-radius: 8px;\n\n  &:hover {\n    color: #d2b5f0;\n    text-decoration: underline;\n    // background: #D2B5F0;\n    // box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.5);\n    // ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var StyledMenuItemContainer = styled.div.withConfig({
    componentId: "sc-beaeee00-0"
})(_templateObject(), function(param) {
    var $isActive = param.$isActive, $variant = param.$variant, theme = param.theme;
    return $isActive && $variant === "subMenu" && '\n      // &:after{\n      //   content: "";\n      //   position: absolute;\n      //   bottom: 0;\n      //   height: 4px;\n      //   width: 100%;\n      //   background-color: '.concat(theme.colors.primary, ";\n      //   border-radius: 2px 2px 0 0;\n      // }\n    ");
});
var StyledMenuItem = styled.a.withConfig({
    componentId: "sc-beaeee00-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, $isActive = param.$isActive;
    return $isActive ? "#6C25B4" : theme.colors.textNav;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.xl;
}, function(param) {
    var $statusColor = param.$statusColor, theme = param.theme;
    return $statusColor && '\n    &:after {\n      content: "";\n      border-radius: 100%;\n      background: '.concat(theme.colors[$statusColor], ";\n      height: 8px;\n      width: 8px;\n      margin-left: 12px;\n    }\n  ");
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" ? "\n    padding: 0 12px;\n    height: 24px;\n    // margin-right: 24px;\n  " : "\n    padding: 4px 4px 0px 4px;\n    height: 42px;\n  ";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "#FBAB33" : "";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "0px 4px 4px rgba(255, 255, 255, 0.5)" : "";
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" && "border-radius: 8px;";
});
export default StyledMenuItem;
