import { useMemo } from 'react'
// import { useActiveIfoWithBlocks } from 'hooks/useActiveIfoWithBlocks'
// import { useCurrentBlock } from 'state/block/hooks'
// import { getStatus } from 'views/Ifos/hooks/helpers'
import { useCompetitionStatus } from './useCompetitionStatus'

export const useMenuItemsStatus = (): Record<string, string> => {
  // const currentBlock = useCurrentBlock()
  // const activeIfo = useActiveIfoWithBlocks()
  const competitionStatus = useCompetitionStatus()

  // const ifoStatus =
  //   currentBlock && activeIfo && activeIfo.endBlock > currentBlock
  //     ? getStatus(currentBlock, activeIfo.startBlock, activeIfo.endBlock)
  //     : null

  return useMemo(() => {
    return {
      '/competition': competitionStatus,
      // '/ifo': ifoStatus === 'coming_soon' ? 'soon' : ifoStatus,
    }
  }, [competitionStatus])
}
