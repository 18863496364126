import { ChainId } from '@pancakeswap/sdk'
import { CONTRACT_LOCKUP } from 'config/env'

export default {
  masterChef: {
    97: '',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  },
  // TODO: multi
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0xdcE0505f296F8c76D176C153Be3f9BE1e6a89e92',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '',
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  teamPull: {
    [ChainId.BSC]: '',
    [ChainId.DX_CHAIN]: CONTRACT_LOCKUP,
  },
  nftFarm: {
    [ChainId.BSC]: '0x78631bbD094B8cE0B308aa18D86b78b986AC8574',
    [ChainId.DX_CHAIN]: "0x0554c6BF0F795ec27aF6BAFa4DCA7968Ca390730",
  },
}
