import { useCallback } from 'react'
import { ChainType, CHAIN_LIST } from '@pancakeswap/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const useCurrentChain = (forceSelectChain?: string): [ChainType, (id: number) => void] => {
  const { chainId } = useActiveWeb3React()
  const getChain = useCallback((idChain) => CHAIN_LIST[idChain], [])
  return [CHAIN_LIST[forceSelectChain ?? chainId], getChain]
}

export default useCurrentChain
