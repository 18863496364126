import _define_property from "@swc/helpers/src/_define_property.mjs";
import { WNATIVE } from "./entities/token";
import { ChainId, FACTORY_ADDRESS_MAP, INIT_CODE_HASH_MAP, MULTICALL_ADDRESS_MAP, MULTICALL_BLOCK_CREATE_MAP, ROUTER_ADDRESS_MAP } from "./constants";
import { RPC_URLS } from "./rpc";
var _obj;
/* RPC Chain list */ export var BASE_BSC_SCAN_URLS = (_obj = {}, _define_property(_obj, ChainId.BSC, "https://bscscan.com"), _define_property(_obj, ChainId.DX_CHAIN, "https://dxdscan.com"), _obj);
// export const CHAIN_LIST: { [chainId in ChainId]: ChainType } = {
export var CHAIN_LIST = _define_property({}, ChainId.DX_CHAIN, {
    name: "DxD Chain Mainnet",
    chainId: ChainId.DX_CHAIN,
    network: "dxd",
    isMainnet: true,
    rpcUrls: {
        default: RPC_URLS[ChainId.DX_CHAIN][0],
        public: RPC_URLS[ChainId.DX_CHAIN]
    },
    scanURL: BASE_BSC_SCAN_URLS[ChainId.DX_CHAIN],
    nativeCurrency: {
        name: "DXD",
        symbol: "DXD",
        decimals: 18
    },
    wNativeCurrency: WNATIVE[ChainId.DX_CHAIN],
    routerAddress: ROUTER_ADDRESS_MAP[ChainId.DX_CHAIN],
    factoryAddress: FACTORY_ADDRESS_MAP[ChainId.DX_CHAIN],
    multicall: {
        address: MULTICALL_ADDRESS_MAP[ChainId.DX_CHAIN],
        blockCreated: MULTICALL_BLOCK_CREATE_MAP[ChainId.DX_CHAIN],
        INIT_CODE_HASH: INIT_CODE_HASH_MAP[ChainId.DX_CHAIN]
    }
});
