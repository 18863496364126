import { CHAIN_LIST } from '@pancakeswap/sdk'
import { StaticJsonRpcProvider } from '@ethersproject/providers'

export function rpcProviderByChain(chainId) {
  const currentRpcChain = CHAIN_LIST[chainId].rpcUrls.default
  return new StaticJsonRpcProvider(currentRpcChain)
}
export const bscRpcProvider = new StaticJsonRpcProvider('https://node1.dxdchain.com')

export default null
