import { Profile } from 'state/types'
import { PancakeProfile } from 'config/abi/types/PancakeProfile'
// import profileABI from 'config/abi/pancakeProfile.json'
// import { multicallv2 } from 'utils/multicall'
// import { getPancakeProfileAddress } from 'utils/addressHelpers'

export interface GetProfileResponse {
  hasRegistered: boolean
  profile?: Profile
}

// const transformProfileResponse = (
//   profileResponse: Awaited<ReturnType<PancakeProfile['getUserProfile']>>,
// ): Partial<Profile> => {
//   const { 0: userId, 1: numberPoints, 2: teamId, 3: collectionAddress, 4: tokenId, 5: isActive } = profileResponse

//   return {
//     userId: userId.toNumber(),
//     points: numberPoints.toNumber(),
//     teamId: teamId.toNumber(),
//     tokenId: tokenId.toNumber(),
//     collectionAddress,
//     isActive,
//   }
// }

const profileApi = process.env.NEXT_PUBLIC_API_PROFILE

export const getUsername = async (address: string): Promise<string> => {
  try {
    const response = await fetch(`${profileApi}/api/users/${address.toLowerCase()}`)

    if (!response.ok) {
      return ''
    }

    const { username = '' } = await response.json()

    return username
  } catch (error) {
    return ''
  }
}

export const getProfile = async (address: string): Promise<GetProfileResponse> => {
  return null
}
