import styled from 'styled-components'
import { useMemo, useState } from 'react'
import { escapeRegExp } from 'utils'
import { Text, Button, Input, Flex, Box, ThemeSwitcher } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import useTheme from 'hooks/useTheme'

import QuestionHelper from '../../QuestionHelper'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const StyledButton = styled(Button)`
  font-size: 14px;
  padding: 4px 8px;
  min-height: 44px;
  min-width: 50px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 4px 14px;
    font-size: 20px;
  }

  :not(:last-child) {
    margin-right: 12px;

    ${({ theme }) => theme.mediaQueries.sm} {
      margin-right: 20px;
    }
  }
`

const InputBox = styled(Box)`
  height: 100%;
  max-width: 60px;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 80px;
  }

  input {
    height: 100%;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
    padding: 4px 8px;
    box-shadow: none;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 4px 14px;
      font-size: 20px;
    }

    ::placeholder {
      color: #d2b5f0;
    }
  }
`

const DealLineInputBox = styled(Box)`
  min-height: 44px;
  min-width: 56px;

  input {
    height: 100%;
    border-radius: 8px;
    padding: 4px 8px;

    text-align: center;
    color: #fff;
    font-size: 16px;

    background: #333333;
    box-shadow: none;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 4px 14px;
      font-size: 20px;
    }

    ::placeholder {
      color: #d2b5f0;
    }
  }
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
const THREE_DAYS_IN_SECONDS = 60 * 60 * 24 * 3

const SlippageTabs = () => {
  const { isDark, setTheme } = useTheme()

  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 60 && valueAsInt < THREE_DAYS_IN_SECONDS) {
        setTtl(valueAsInt)
      } else {
        deadlineError = DeadlineError.InvalidInput
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleTheme = useMemo(() => {
    // return () => setTheme(isDark ? 'light' : 'dark')
    return () => setTheme('light')
  }, [setTheme])

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb="24px">
        <Flex mb="12px" alignItems="center">
          <Text color="primary" fontSize={['14px', null, '20px']} medium>
            {t('Slippage Tolerance')}
          </Text>
          <QuestionHelper
            text={t(
              'Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.',
            )}
            placement="top-start"
            ml="8px"
          />
        </Flex>
        <Flex flexWrap="wrap">
          <StyledButton
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(10)
            }}
            variant={userSlippageTolerance === 10 ? 'primary' : 'white'}
            style={{ borderRadius: '10px' }}
          >
            0.1%
          </StyledButton>
          <StyledButton
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(50)
            }}
            variant={userSlippageTolerance === 50 ? 'primary' : 'white'}
            style={{ borderRadius: '10px' }}
          >
            0.5%
          </StyledButton>
          <StyledButton
            scale="sm"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(100)
            }}
            variant={userSlippageTolerance === 100 ? 'primary' : 'white'}
            style={{ borderRadius: '10px' }}
          >
            1.0%
          </StyledButton>
          <Flex alignItems="center">
            <InputBox>
              <Input
                scale="sm"
                inputMode="decimal"
                pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                placeholder={(userSlippageTolerance / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                }}
                onChange={(event) => {
                  if (event.currentTarget.validity.valid) {
                    parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                  }
                }}
                isWarning={!slippageInputIsValid}
                isSuccess={![10, 50, 100].includes(userSlippageTolerance)}
                style={{ borderRadius: '10px' }}
              />
            </InputBox>
            <Text color="primary" bold ml="8px">
              %
            </Text>
          </Flex>
        </Flex>
        {!!slippageError && (
          <Text
            fontSize={['12px', null, '14px']}
            color={slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'}
            mt="8px"
          >
            {slippageError === SlippageError.InvalidInput
              ? t('Enter a valid slippage percentage')
              : slippageError === SlippageError.RiskyLow
              ? t('Your transaction may fail')
              : t('Your transaction may be frontrun')}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Flex alignItems="center">
          <Text color="primary" fontSize={['14px', null, '20px']} medium>
            {t('Tx deadline (mins)')}
          </Text>
          <QuestionHelper
            text={t('Your transaction will revert if it is left confirming for longer than this time.')}
            placement="top-start"
            ml="8px"
          />
        </Flex>
        <Flex>
          <DealLineInputBox width="52px">
            <Input
              scale="sm"
              inputMode="numeric"
              pattern="^[0-9]+$"
              isWarning={!!deadlineError}
              onBlur={() => {
                parseCustomDeadline((ttl / 60).toString())
              }}
              placeholder={(ttl / 60).toString()}
              value={deadlineInput}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomDeadline(event.target.value)
                }
              }}
            />
          </DealLineInputBox>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text color="primary" fontSize={['14px', null, '20px']} medium>
            {t('Dark mode')}
          </Text>
        </Flex>
        <Flex>
          <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SlippageTabs
