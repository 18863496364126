import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { bscRpcProvider } from 'utils/providers'
import poolsConfig from 'config/constants/pools'
import { PoolCategory } from 'config/constants/types'
import { CAKE } from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getMasterChefAddress,
  getCakeVaultAddress,
  getMulticallAddress,
  getFarmAuctionAddress,
  getTradingCompetitionAddressMoD,
  getBunnySpecialXmasAddress,
  getICakeAddress,
  getZapAddress,
  getCakeFlexibleSideVaultAddress,
  getPancakeProfileAddress,
} from 'utils/addressHelpers'

// ABI

import bep20Abi from 'config/abi/erc20.json'
import profileABI from 'config/abi/pancakeProfile.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import tradingCompetitionMoDAbi from 'config/abi/tradingCompetitionMoD.json'
import cakeVaultV2Abi from 'config/abi/cakeVaultV2.json'
import cakeFlexibleSideVaultV2Abi from 'config/abi/cakeFlexibleSideVaultV2.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialXmasAbi from 'config/abi/bunnySpecialXmas.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import zapAbi from 'config/abi/zap.json'
import iCakeAbi from 'config/abi/iCake.json'

// Types
import type {
  FarmAuction,
  Erc20,
  Erc721,
  Cake,
  Masterchef,
  SousChef,
  SousChefV2,
  LpToken,
  Multicall,
  CakeVaultV2,
  CakeFlexibleSideVaultV2,
  ICake,
  TradingCompetitionMoD,
  Zap,
  PancakeProfile,
} from 'config/abi/types'
import { ChainId } from '@pancakeswap/sdk'

export const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? bscRpcProvider
  return new Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(bep20Abi, address, signer) as Erc20
}
export const getErc721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(erc721Abi, address, signer) as Erc721
}
export const getLpContract = (address: string, signer?: Signer | Provider) => {
  return getContract(lpTokenAbi, address, signer) as LpToken
}
export const getSouschefContract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer) as SousChef
}
export const getSouschefV2Contract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer) as SousChefV2
}

export const getCakeContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract(cakeAbi, chainId ? CAKE[chainId].address : CAKE[ChainId.BSC].address, signer) as Cake
}
export const getProfileContract = (signer?: Signer | Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer) as PancakeProfile
}
export const getMasterchefContract = (signer?: Signer | Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer) as Masterchef
}
export const getTradingCompetitionContractMoD = (signer?: Signer | Provider) => {
  return getContract(tradingCompetitionMoDAbi, getTradingCompetitionAddressMoD(), signer) as TradingCompetitionMoD
}
export const getCakeVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract(cakeVaultV2Abi, getCakeVaultAddress(), signer) as CakeVaultV2
}

export const getCakeFlexibleSideVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract(cakeFlexibleSideVaultV2Abi, getCakeFlexibleSideVaultAddress(), signer) as CakeFlexibleSideVaultV2
}
export const getMulticallContract = (chainId) => {
  return getContract(MultiCallAbi, getMulticallAddress(chainId), bscRpcProvider) as Multicall
}
export const getBunnySpecialXmasContract = (signer?: Signer | Provider) => {
  return getContract(bunnySpecialXmasAbi, getBunnySpecialXmasAddress(), signer)
}
export const getFarmAuctionContract = (signer?: Signer | Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as unknown as FarmAuction
}
export const getZapContract = (signer?: Signer | Provider) => {
  return getContract(zapAbi, getZapAddress(), signer) as Zap
}
export const getIfoCreditAddressContract = (signer?: Signer | Provider) => {
  return getContract(iCakeAbi, getICakeAddress(), signer) as ICake
}
