import { createReducer } from '@reduxjs/toolkit'
import { setLockupsListToState, setReportAllToState, setTokenInfosToState } from './actions'

export interface MintState {
  tokenInfos: any
  lockupsList: {
    [chainId: number]: {
      [account: string]: any
    }
  }
  reportAll: any
}

const initialState: MintState = {
  tokenInfos: [],
  lockupsList: {},
  reportAll: {},
}

export default createReducer<MintState>(initialState, (builder) =>
  builder
    .addCase(setLockupsListToState, (state, { payload: { chainId, account, lockupsList } }) => {
      if (!state.lockupsList[chainId]) state.lockupsList[chainId] = {}
      state.lockupsList[chainId][account] = lockupsList
    })
    .addCase(setReportAllToState, (state, { payload: { reportAll } }) => {
      state.reportAll = reportAll
    })
    .addCase(setTokenInfosToState, (state, { payload }) => {
      state.tokenInfos = payload
    }),
)
