import _define_property from "@swc/helpers/src/_define_property.mjs";
import { ChainId } from "./constants";
var _obj;
export var RPC_URLS = (_obj = {}, _define_property(_obj, ChainId.BSC, [
    "https://bsc-dataseed1.binance.org",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed1.ninicoin.io", 
]), _define_property(_obj, ChainId.DX_CHAIN, [
    "https://node1.dxdchain.com"
]), _obj);
