// used to construct the list of all pairs we consider by default in the frontend
import { ChainId, Token } from '@pancakeswap/sdk'
import blockAddress from './blockAddress'
import { bscTokens, bscTestnetTokens } from './tokens'
import { ChainTokenList } from './types'

export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BSC]: [bscTokens.wbnb],
  [ChainId.DX_CHAIN]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.BSC]: [[bscTokens.cake, bscTokens.wbnb]],
}

export const NetworkContextName = 'NETWORK'
export const CACHE_CHAIN_ID = 'swapDefaultActiveChainId'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = blockAddress

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
// 72h, default time for locked token LPs
// export const DEFAULT_LOCK_TIME_LPS = 60 * 60 * 72
export const DEFAULT_LOCK_TIME_LPS = 60 * 15 // testing 15 minutes

export const LIMIT_LOCKUP_SHOW = 15

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const NOT_ON_SALE_SELLER = '0x0000000000000000000000000000000000000000'

export const FARM_AUCTION_HOSTING_IN_SECONDS = 604800

export const PREDICTION_TOOLTIP_DISMISS_KEY = 'prediction-switcher-dismiss-tooltip'

export const EXCHANGE_DOCS_URLS = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange'

export const DEFAULT_DECIMAL = 1e18
export const GAS_PRICE_DEFAULT = '5000000000'
