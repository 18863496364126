import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#EC3434",
    primary: "#6C25B4",
    primaryBright: "#53DEE9",
    primaryDark: "#2B0E4A",
    secondary: "#6C25B4",
    success: "#00BE4C",
    warning: "#6C25B4"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    background: "#FAF9FA",
    backgroundDisabled: "#CFCFCF",
    textNav: "#9C9C9C",
    backgroundNav: "#FFFFFF",
    backgroundAlert: "#FAF9FA",
    backgroundAlt: "#27262C",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    cardBorder: "#6C25B4",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "rgba(255, 255, 255, 0.4)",
    inputSecondary: "#d7caec",
    tertiary: "#EFF4F5",
    text: "#333333",
    textWhite: "#FFFFFF",
    textDisabled: "#333333",
    textSubtle: "#aaa",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    secondary: "#FF941C",
    background: "#08060B",
    backgroundDisabled: "#3c3742",
    textNav: "#5AC132",
    backgroundNav: "#303030",
    backgroundAlert: "#FAF9FA",
    backgroundAlt: "#120039",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "#355F36",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#0A011E",
    inputSecondary: "#262130",
    primaryDark: "#0098A1",
    tertiary: "#353547",
    text: "#ffffff",
    textWhite: "#191326",
    textDisabled: "#666171",
    textSubtle: "#B8ADD2",
    disabled: "#524B63",
    gradients: {
        bubblegum: "#000",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
