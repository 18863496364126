import styled from 'styled-components'
import { Flex, Button, Text } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from '@pancakeswap/localization'

import { useGasPriceManager } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'

const StyledButton = styled(Button)`
  font-size: 14px;
  padding: 4px 8px;
  min-height: 44px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 4px 14px;
    font-size: 20px;
  }

  :not(:last-child) {
    margin-right: 12px;
    margin-bottom: 8px;

    ${({ theme }) => theme.mediaQueries.xs} {
      margin-bottom: 0;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      margin-right: 20px;
    }
  }
`

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()

  return (
    <Flex flexDirection="column">
      <Flex mb={['16px', null, '32px']} alignItems="center">
        <Text color="primary" fontSize={['14px', null, '20px']} medium>
          {t('Default Transaction Speed (GWEI)')}
        </Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="8px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <StyledButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.default ? 'primary' : 'white'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: GAS_PRICE.default })}
        </StyledButton>
        <StyledButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.fast ? 'primary' : 'white'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: GAS_PRICE.fast })}
        </StyledButton>
        <StyledButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.instant ? 'primary' : 'white'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: GAS_PRICE.instant })}
        </StyledButton>
      </Flex>
    </Flex>
  )
}

export default GasSettings
