import { ChainId, Token, WBNB } from '@pancakeswap/sdk'

/* Cake */
const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)
const CAKE_TESTNET = new Token(
  ChainId.DX_CHAIN,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)
export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.DX_CHAIN]: CAKE_TESTNET,
}

/* USDC */
const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)
const USDC_TESTNET = new Token(
  ChainId.DX_CHAIN,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)
export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.DX_CHAIN]: USDC_TESTNET,
}

/* BUSD */
const BUSD_MAINNET = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)
const BUSD_TESTNET = new Token(
  ChainId.DX_CHAIN,
  '0x94787c4ce8D4C82Dc00A4F7f912eA1c1Bd0139b2',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)
export const BUSD: Record<ChainId, Token> = {
  [ChainId.BSC]: BUSD_MAINNET,
  [ChainId.DX_CHAIN]: BUSD_TESTNET,
}

export const bscTokens = {
  wbnb: WBNB[ChainId.DX_CHAIN],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(
    ChainId.DX_CHAIN,
    '0xFADe6c45B022b2A675C13f0c6df8843492586C02',
    18,
    'WWE',
    'WWE',
    'https://www.binance.com/',
  ),
  cake: CAKE[ChainId.BSC],
  busd: BUSD[ChainId.BSC],
}

export const bscTestnetTokens = {
  wbnb: WBNB[ChainId.DX_CHAIN],
  cake: CAKE[ChainId.DX_CHAIN],
  busd: BUSD[ChainId.DX_CHAIN],
}
