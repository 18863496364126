import { useState, useEffect } from 'react'
import { CHAIN_LIST } from '@pancakeswap/sdk'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { CACHE_CHAIN_ID } from 'config/constants'
// import { bscRpcProvider } from 'utils/providers'

// force cache chain id from localStorage
let initForceCacheChainId
if (typeof localStorage === 'object') {
  initForceCacheChainId = localStorage?.getItem(CACHE_CHAIN_ID)
  const checkIsAvailableChain = parseInt(initForceCacheChainId) in CHAIN_LIST
  if (!checkIsAvailableChain) {
    initForceCacheChainId = undefined
  }
}
if (!initForceCacheChainId) {
  initForceCacheChainId = Object.entries(CHAIN_LIST)[0][1].chainId
}

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React()
  const [cacheChainId, setCacheChainId] = useState(chainId ?? parseInt(initForceCacheChainId))

  // here fetch chain from localestorage or default item 0 of chain list
  useEffect(() => {
    if (chainId) {
      localStorage.setItem(CACHE_CHAIN_ID, chainId.toString())
      setCacheChainId(chainId)
    }
  }, [chainId])

  return {
    library: library ?? undefined,
    chainId: cacheChainId,
    ...web3React,
  }
}

export default useActiveWeb3React
